export const staffModal = () => {
  if (!document.getElementById("staff-modal")) return;

  const modal = document.getElementById("staff-modal");
  const modalOpenBtns = document.querySelectorAll(".js-staff-modal-btn");
  const modalContents = document.querySelectorAll(".js-staff-modal-content");
  const modalClose = document.querySelectorAll(".js-staff-modal-close");

  modalClose.forEach((btn) => {
    btn.addEventListener("click", () => {
      modal.classList.remove("is-active");
      modalContents.forEach((content) => {
        content.classList.remove("is-active");
      });
    });
  });

  modalOpenBtns.forEach((btn, i) => {
    btn.addEventListener("click", () => {
      console.log(i);
      modalContents[i].classList.add("is-active");
      modal.classList.add("is-active");
    });
  });
};
