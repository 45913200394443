import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const infiniteGallerySlider = () => {
  // return if there's no slider element
  if (!document.querySelector(".js-infinite-gallery-slider")) {
    return;
  }

  const splide = new Splide(".js-infinite-gallery-slider", {
    type: "loop",
    gap: "3rem",
    perPage: 3,
    arrows: false,
    drag: false,
    pagination: false,
    autoScroll: {
      pauseOnHover: false,
    },
    heightRatio: 0.2,
    breakpoints: {
      768: {
        perPage: 1,
        heightRatio: 0.8,
      },
    },
  });
  splide.mount({ AutoScroll });
};
