import $ from "jquery";

export const navigationAccordion = () => {
  // only when the viewport is less than 768px using matchMedia
  const mediaQuery = window.matchMedia("(max-width: 768px)");

  const listener = (event) => {
    if (event.matches) {
      const triggers = $(".js-navi-accordion");

      triggers.each(function (index) {
        const trigger = $(this).find(".js-navi-accordion-trigger");
        const content = $(this).find(".js-navi-accordion-content");

        trigger.on("click", function () {
          $(this).toggleClass("is-open");
          content.slideToggle();
        });
      });
    } else {
      const triggers = $(".js-navi-accordion");
      triggers.each(function (index) {
        const trigger = $(this).find(".js-navi-accordion-trigger");
        trigger.off("click");
      });
    }
  };

  // listen for changes
  mediaQuery.addEventListener("change", listener);

  // initialize
  listener(mediaQuery);
};
