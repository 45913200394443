import $ from "jquery";

export const hamburgerMenu = () => {
  const btn = document.getElementById("hamburger-btn");

  function bodyFixed() {
    //変数の設定
    var $body = $("body");

    $body.css({
      margin: 0,
    });

    //スクロール量を保存
    var scrollTop;

    //スクロールを固定
    function bodyFixedOn() {
      scrollTop = $(window).scrollTop();

      $body.css({
        position: "fixed",
        top: -scrollTop,
      });
    }

    //スクロールの固定を解除
    function bodyFixedOff() {
      $body.css({
        position: "",
        top: "",
      });

      $(window).scrollTop(scrollTop);
    }

    //モーダルのトリガーをクリックしたとき

    $("#hamburger-btn").on("click", function () {
      if ($body.hasClass("is-hamburger-menu-active")) {
        bodyFixedOff();
        $(this).removeClass("is-active");
        $body.removeClass("is-hamburger-menu-active");
      } else {
        bodyFixedOn();
        $(this).addClass("is-active");
        $body.addClass("is-hamburger-menu-active");
      }
    });
  }

  bodyFixed();
};
