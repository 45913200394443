import $ from "jquery";
import { infiniteGallerySlider } from "./pages/home/infiniteGallerySlider";
import { headerSubNavOnHover } from "./common/headerSubNavOnHover";
import { stickyCity } from "./pages/home/stickyCity";
import { messageText } from "./pages/home/messageText";
import { mainSlider } from "./common/mainSlider";
import { hamburgerMenu } from "./common/hamburgerMenu";
import { navigationAccordion } from "./common/navigationAccordion";
import { smoothScroll } from "./common/smoothScroll";
import { logoInfiniteSlider } from "./pages/company-info/logoInfiniteSlider";
import { staffModal } from "./pages/company-info/staffModal";
import { blogSlider } from "./pages/blog/blogSlider";
import { inview } from "./common/inview";
import { reload } from "./common/reload";

$(function () {
  // common
  smoothScroll();
  hamburgerMenu();
  headerSubNavOnHover();
  navigationAccordion();
  infiniteGallerySlider();
  mainSlider();
  reload();
  inview();

  // home
  stickyCity();
  messageText();

  // company-info
  logoInfiniteSlider();
  staffModal();

  // blog
  blogSlider();
});
