import $ from "jquery";

export const headerSubNavOnHover = () => {
  const navLink = $(".js-sub-nav-wrapper");

  navLink
    .on("mouseover", function () {
      $(this).find(".js-sub-nav-trigger").addClass("is-active");
      $(this).find(".js-sub-nav").addClass("is-active");
    })
    .on("mouseout", function () {
      $(this).find(".js-sub-nav-trigger").removeClass("is-active");
      $(this).find(".js-sub-nav").removeClass("is-active");
    });
};
