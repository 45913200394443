import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const stickyCity = () => {
  const cityArea = document.querySelector(".js-sticky-city");
  const cityEl = document.querySelector(".js-sticky-city-el");
  const cityImg = document.querySelector(".js-sticky-city-img");
  const cityOverlay = document.querySelector(".js-sticky-city-overlay");

  if (!cityArea || !cityEl) return;

  const cityAreaTl = gsap.timeline({
    scrollTrigger: {
      trigger: cityArea,
      start: "top-=1",
      end: "bottom bottom",
      scroller: "body",
      onEnter: () => {
        cityEl.classList.add("is-sticky");
      },
      onLeaveBack: () => {
        cityEl.classList.remove("is-sticky");
      },
      onEnterBack: () => {
        cityEl.classList.add("is-sticky");
      },
      onLeave: () => {
        cityEl.classList.remove("is-sticky");
      },
      onUpdate: (self) => {
        const progress = self.progress;

        cityImg.style.opacity = progress;
        cityOverlay.style.opacity = 1 - progress;
      },
    },
  });
};
