import { Splide } from "@splidejs/splide";

export const blogSlider = () => {
  // if (!document.querySelector(".js-blog-slider-01")) {
  //   return;
  // }

  // const slider01 = new Splide(".js-blog-slider-01", {
  //   gap: "2.4rem",
  //   fixedWidth: "32rem",
  //   classes: {
  //     pagination: "splide__pagination blog-slider__pagination",
  //     page: "splide__pagination__page blog-slider__page",
  //     arrows: "splide__arrows blog-slider__arrows",
  //     arrow: "splide__arrow blog-slider__arrow",
  //   },
  //   breakpoints: {
  //     768: {
  //       fixedWidth: null,
  //       perPage: 1,
  //     },
  //   },
  // });
  // slider01.mount();

  const slider02 = new Splide(".js-blog-slider-02", {
    gap: "2.4rem",
    fixedWidth: "32rem",
    classes: {
      pagination: "splide__pagination blog-slider__pagination",
      page: "splide__pagination__page blog-slider__page -is-02",
      arrows: "splide__arrows blog-slider__arrows",
      arrow: "splide__arrow blog-slider__arrow",
    },
    breakpoints: {
      768: {
        fixedWidth: null,
        perPage: 1,
      },
    },
  });
  slider02.mount();
};
