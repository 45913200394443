import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const messageText = () => {
  const messageText = document.querySelectorAll(".js-message-text > *");

  if (!messageText) return;

  messageText.forEach((message) => {
    const messageTl = gsap.timeline({
      scrollTrigger: {
        trigger: message,
        start: "top 60%",
        end: "bottom 20%",
        scrub: true,
        scroller: "body",
        onUpdate: (self) => {
          const progress = self.progress;
          if (progress <= 0.4) {
            message.style.opacity = progress * 2.5;
          } else if (progress <= 0.8) {
            message.style.opacity = 1;
          } else {
            message.style.opacity = 1 - (progress - 0.8) * 5;
          }
        },
      },
    });
  });
};
