import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const mainSlider = () => {
  if (!document.querySelector(".js-main-slider")) {
    return;
  }

  const splide = new Splide(".js-main-slider", {
    type: "loop",
    gap: "0",
    autoWidth: true,
    arrows: false,
    drag: false,
    pagination: false,
    autoScroll: {
      pauseOnHover: false,
    },
  });
  splide.mount({ AutoScroll });
};
