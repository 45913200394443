export const inview = () => {
  const els = document.querySelectorAll(".inview");

  const cb = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("inview-is-visible");
        observer.unobserve(entry.target);
      }
    });
  };

  const options = {
    rootMargin: "-15% 0px",
  };

  const io = new IntersectionObserver(cb, options);

  els.forEach((el) => {
    io.observe(el);
  });
};
